import { getGatsbyImageData } from "@imgix/gatsby"
import type * as pt from "@prismicio/types"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyInlineGallery } from "../../../slices/PageDataBodyInlineGallery"

type Slide = React.ComponentPropsWithoutRef<
	typeof PageDataBodyInlineGallery
>["slides"][number]

const getSlideImage = (data: { src: string; aspectRatio: number }) => {
	return getGatsbyImageData({
		width: 800,
		layout: "constrained",
		...data,
	})
}

const slide = (data: Partial<Slide> = {}): Slide => {
	return {
		image: getSlideImage({
			src: "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab",
			aspectRatio: 1513 / 1008,
		}),
		imageAlt: "",
		caption: [
			{ text: lorem.sentences(1), spans: [], type: "paragraph" },
		] as pt.RichTextField,
		...data,
	}
}

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyInlineGallery
				slides={[
					slide(),
					slide({
						image: getSlideImage({
							src: "https://images.unsplash.com/photo-1477959858617-67f85cf4f1df",
							aspectRatio: 891 / 548,
						}),
					}),
					slide({
						image: getSlideImage({
							src: "https://images.unsplash.com/photo-1519010470956-6d877008eaa4",
							aspectRatio: 673 / 1008,
						}),
					}),
					slide({
						image: getSlideImage({
							src: "https://images.unsplash.com/photo-1449824913935-59a10b8d2000",
							aspectRatio: 891 / 594,
						}),
					}),
				]}
			/>
		</CmsGuideLayout>
	)
}

export default Component
